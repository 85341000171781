export default function configData() {
  const object = JSON.parse(localStorage.getItem('configData'))
  const configs = []
  for (let i = 0; i < object.length; i += 1) {
    const k = object[i].name
    const val = object[i].value
    configs[k] = val
  }

  // const businessName = configs.business_name
  // const businessPhone = configs.business_phone
  // const businessWebsite = configs.business_website
  // const businessEmail = configs.business_email
  // const businessAddress = configs.business_address
  // const businessLogo = configs.business_logo

  return {
    configs,

  }
}
